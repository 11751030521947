<template>
    <vx-card title="View Picking Process">
        <vs-table stripe border :data="table.data">
            <template slot="thead">
                <vs-th sort-key="">Delivery Plan</vs-th>
                <vs-th sort-key="">Picking List Number</vs-th>
                <vs-th sort-key="">Picking List Type</vs-th>
                <vs-th sort-key="">Sales Order Data</vs-th>
                <vs-th sort-key="">Delivery Order Data</vs-th>
                <vs-th sort-key="">Ship-to Data</vs-th>
                <vs-th sort-key="">Request Delivery Date (RDD)</vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>{{ tr.SKUCode }}</vs-td>
                    <vs-td>{{ tr.SKUName }}</vs-td>
                    <vs-td>{{ tr.HU }}</vs-td>
                    <vs-td>{{ tr.Quantity }}</vs-td>
                    <vs-td>{{ tr.storageArea }}</vs-td>
                    <vs-td>{{ tr.batchNumber }}</vs-td>
                    <vs-td>{{ tr.expDate }}</vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <br>
        <br>
        <table class="table table-stripe" style="width:100%">
            <thead>
                <tr>
                    <th style="vertical-align: middle;" rowspan="2">SKU Code</th>
                    <th style="vertical-align: middle;" rowspan="2">SKU Name</th>
                    <th style="text-align: center;" colspan="5">Suggestion</th>
                    <th style="text-align: center;" colspan="5">Actual</th>
                    <th style="vertical-align: middle;" rowspan="2"></th>
                </tr>
                <tr>
                    <th>HU</th>
                    <th>Quantity</th>
                    <th>Storage Area</th>
                    <th>Batch Number</th>
                    <th>Expired Date</th>
                    <th>HU</th>
                    <th>Quantity</th>
                    <th>Storage Area</th>
                    <th>Batch Number</th>
                    <th>Expired Date</th>
                </tr>
            </thead>
            <tbody>
                <template>
                    <tr v-for="(tr, index) in pickProc" :key="index" style="padding-bottom;: 5px">
                        <td class="td vs-table--td" style="padding: 2px">
                            <vs-td>{{ tr.SKUCode }}</vs-td>
                        </td>
                        <td class="td vs-table--td" style="padding: 2px">
                            <vs-td>{{ tr.SKUName }}</vs-td>
                        </td>
                        <td class="td vs-table--td" style="padding: 2px">
                            <vs-td>{{ tr.HU }}</vs-td>
                        </td>
                        <td class="td vs-table--td" style="padding: 2px">
                            <vs-td>{{ tr.Quantity }}</vs-td>
                        </td>
                        <td class="td vs-table--td" style="padding: 2px">
                            <vs-td>{{ tr.storageArea }}</vs-td>
                        </td>
                        <td class="td vs-table--td" style="padding: 2px">
                            <vs-td>{{ tr.batchNumber }}</vs-td>
                        </td>
                        <td class="td vs-table--td" style="padding: 2px">
                            <vs-td>{{ tr.expDate }}</vs-td>
                        </td>
                        <td class="td vs-table--td" style="padding: 2px">
                            <vs-td>{{ tr.HU }}</vs-td>
                        </td>
                        <td class="td vs-table--td" style="padding: 2px">
                            <vs-td>{{ tr.Quantity }}</vs-td>
                        </td>
                        <td class="td vs-table--td" style="padding: 2px">
                            <vs-td>{{ tr.storageArea }}</vs-td>
                        </td>
                        <td class="td vs-table--td" style="padding: 2px">
                            <vs-td>{{ tr.batchNumber }}</vs-td>
                        </td>
                        <td class="td vs-table--td" style="padding: 2px">
                            <vs-td>{{ tr.expDate }}</vs-td>
                        </td>
                        <td class="td vs-table--td" style="text-align: center">
                            <div class="vx-input-group flex">
                                <vs-button v-if="pickProc.length > 1" @click.stop="removeRow(index)" size="small"
                                    color="danger" icon-pack="feather" icon="icon-trash" style="margin-right: 5px"
                                    title="Remove Row" />
                                <vs-button v-if="index == pickProc.length - 1" @click.stop="addRow()" size="small"
                                    color="success" icon-pack="feather" icon="icon-plus" title="Add Row" />
                            </div>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
        <br>
        <div class="mb-6 space-x-1 flex">
            <div class="">
                <vs-button color="primary" @click="handleSave">SAVE</vs-button>
            </div>
        </div>
    </vx-card>
</template>

<script>
import moment from "moment";

export default {
    components: {},
    props: {
        baseUrl: {
            type: String,
        },
        draw: {
            type: Number,
        },
    },
    data() {
        return {
            readonly: true,
            table: this.tableDefaultState(),
            pickProc: [
                {
                    SKUCode: "89898989",
                    SKUName: "ULI JONGGOL",
                    HU: "uwowowow",
                    Quantity: "98246",
                    storageArea: "MW9NF9P",
                    batchNumber: "823748280374",
                    expDate: "24 JANUARI 2027",
                }
            ]
        }
    },
    methods: {
        tableDefaultState() {
            return {
                data: [{
                    SKUCode: "89898989",
                    SKUName: "ULI JONGGOL",
                    HU: "uwowowow",
                    Quantity: "98246",
                    storageArea: "MW9NF9P",
                    batchNumber: "823748280374",
                    expDate: "24 JANUARI 2027",
                }],
                length: 10,
                page: 1,
                search: "",
                order: "id",
                sort: "desc",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        getData() {
            this.$vs.loading();
            this.$http
                .get(this.baseUrl + "/table", {
                    params: {
                        length: this.table.length,
                        page: this.table.page,
                        search: this.table.search,
                        order: this.table.order,
                        sort: this.table.sort,
                        // ...
                        date: moment(this.dateNow).format("YYYY-MM-DD"),
                    },
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        // this.table.total = resp.data.total_record;
                        // this.table.totalPage = resp.data.total_page;
                        // this.table.totalSearch = resp.data.total_record_search;
                        // this.table.length = resp.data.total_record_per_page;
                        // this.table.data = resp.data.records;
                        this.table.total = 1;
                        this.table.totalPage = 1;
                        this.table.totalSearch = 1;
                        this.table.length = 1;
                        this.table.data = [{
                            SKUCode: "89898989",
                            SKUName: "ULI JONGGOL",
                            HU: "uwowowow",
                            Quantity: "8055555",
                            storageArea: "MW9NF9P",
                            batchNumber: "823748280374",
                            expDate: "24 JANUARI 2027",
                        }];
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
        removeRow(index) {
            this.pickProc.splice(index, 1);
        },
        addRow() {
            this.pickProc.push({
                SKUCode: "89898989",
                SKUName: "ULI JONGGOL",
                HU: "uwowowow",
                Quantity: "98246",
                storageArea: "MW9NF9P",
                batchNumber: "823748280374",
                expDate: "24 JANUARI 2027",
            });

        },
        handleSave() {
            window.history.back();
        },
    },
    mounted() {
        // ...
    },
    watch: {
        detail() {
            this.getData();
        },
    },
}
</script>